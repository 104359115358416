<template>
  <v-card class="mx-auto forgot-card" :loading="loading" :outlined="$vuetify.breakpoint.smAndUp" :flat="$vuetify.breakpoint.xsOnly">
    <v-img class="mx-auto logo" :src="logo"></v-img>
    <div class="forgot-title">
       Recuperación de la cuenta
    </div>
    <div class="step-container">
      <div class="step-title">
        Obtener un enlace de recuperación
      </div>
      <div class="step-description">
        Para obtener un enlace de recuperación, primero ingresa la dirección de correo electrónico con la cual te resgistraste
      </div>
    </div>
    <v-form ref="form" >
      <v-card-text class="forgot-card-text">
        <v-row justify="center" no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="email"
              label="Ingrese su dirección de correo"
              :rules="[isRequired, isEmail]"
              :error-messages="emailServerMessage"
              @input="resetEmailServerMessage"
              outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="text-left actions-column">
            <v-row justify="center" no-gutters>
              <v-col>
                <v-btn class="text-none login-button" text color="primary" @click="goToLogin">
                  Acceder a tu cuenta
                </v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn class="text-none" depressed color="primary" :loading="loading" @click="reset">
                  Enviar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import logo from '@/assets/logo.jpg'
import { isRequired, isEmail } from '@/shared/validations'
import { STATUS_NOT_FOUND } from '@/shared/httpStatuses'

export default {
  mixins: [navigationMixin],
  data () {
    return {
      logo,
      redirect: null,
      email: '',
      emailServerMessage: null
    }
  },
  computed: {
    ...mapState('shared', ['error', 'loading'])
  },
  methods: {
    isRequired,
    isEmail,
    ...mapActions('auth', ['resetPassword']),
    ...mapActions('notification', ['showNotification']),
    resetEmailServerMessage () {
      this.emailServerMessage = null
    },
    setError () {
      if (this.error.code === STATUS_NOT_FOUND) {
        this.emailServerMessage = 'No se ha podido encontrar tu cuenta.'
        return
      }

      this.showNotification({ message: this.error.message })
    },
    goToLogin () {
      const queryParams = {}
      if (this.redirect) {
        queryParams.redirect = this.redirect
      }

      this.goToRouteByName('login', {}, queryParams)
    },
    async reset () {
      if (!this.$refs.form.validate()) {
        return
      }

      const user = { email: this.email }
      await this.resetPassword(user)
      if (this.error) {
        this.setError()
        return
      }

      this.showNotification({ message: 'Se envió un correo electrónico con un enlace de recuperación a ' + this.email })
    }
  },
  mounted () {
    this.redirect = this.$route.query.redirect
  }
}
</script>
<style scoped>
.forgot-card {
  width: 28rem;
  min-height: 31.375rem;
}
.logo {
  margin-top: 2.25rem;
  margin-bottom: 1rem;
  width: 5.625rem;
}
.forgot-title {
  font-size: 1.5rem;
  margin-bottom: 0.9rem;
}
.step-container{
  text-align: left;
  padding-left: 3rem;
  padding-right: 3rem;
}
.step-title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
}
.step-description {
  font-size: 0.875rem;
  margin-bottom: 0.2rem;
}
.forgot-card-text {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
}
.show-password-column {
  margin-top: -1rem;
}
.actions-column {
  margin-top: 1.5rem;
}
.login-button {
  margin-left: -1rem;
}
/* 599px */
@media only screen and (max-width: 37.438rem) {
  .logo {
    margin-top: 1.5rem;
  }
  .step-container{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .forgot-card-text {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
</style>
